import $ from 'jquery';
import slick from 'slick-carousel';

function slide () {

    if ($('.c-slide').length) {
        $('.c-slide').slick({
            infinite: false,
            arrows: true,
            dots: false,
            prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fal fa-chevron-right"></i></button>'
        });
    }
    
}

export {slide};