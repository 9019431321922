import $ from 'jquery';

function goMenu() {

    $('.js-go-menu').on('click', function(e) {
        e.preventDefault();

        var go = $(this).attr('href');

        $('.c-nav').removeClass('c-nav--active');

        $('body, html').animate({
            scrollTop: $('a[name="' + go + '"]').offset().top - $('.c-header').height()
        });

    });
    
}

export { goMenu }