import $ from 'jquery';

function info() {

    $('.js-info').on('click', function(e) {
        e.preventDefault();

        var text = $(this).data('text'),
            images = $(this).data('images'),
            url = $(this).data('url');

        $('.js-info').removeClass('c-nav-slide--active');
        $(this).addClass('c-nav-slide--active');

        $('.js-info--text').text(text);
        $('.js-info--url').attr('href', url);

        //$('.c-slide').find('.u-display-none').removeClass('u-display-none');
        //$('.c-slide').find('.c-slide__item').not('.' + images).parents('.slick-slide').addClass('u-display-none');
        $('.c-slide').slick('slickUnfilter');
        $('.c-slide').slick('slickFilter', (index, item) => {
            if ($(item).find('.' + images).length > 0) {
                return true;
            }
        });

    });
    
}

export { info }