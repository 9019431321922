import $ from 'jquery';

function menu() {

    $('.js-menu').on('click', function(e) {
        e.preventDefault();

        $('.c-nav').toggleClass('c-nav--active');

    });
    
}

export { menu }