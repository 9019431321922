import {slide} from './modules/slide';
import {carousel} from './modules/carousel';
import {toggleClass} from './modules/toggleClass';
import {lazy} from './modules/lazyload';
import {svg} from './modules/svg';
import {info} from './modules/info';
import {menu} from './modules/menu';
import {goMenu} from './modules/goMenu';
import AOS from 'aos';

AOS.init({
    offset: 50
});

slide();
carousel();
toggleClass();
lazy();
svg();
info();
menu();
goMenu();