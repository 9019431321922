import $ from 'jquery';
import slick from 'slick-carousel';

function carousel () {

    if ($('.c-carousel').length) {
        $('.c-carousel').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fal fa-arrow-right"></i></button>',
            dots: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

}

export {carousel};